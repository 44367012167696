import { BooleanFilterDTO, DateFilterDTO, FloatFilterDTO, QueryDTO, sortDirection, StringFilterDTO } from './generated';

export function buildQueryParams<T extends QueryDTO>(params: T): string {
  return JSON.stringify(params);
}

export function blobToDataURL(blob: Blob): Promise<string> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function equalFilterDTO(a?: FilterDTO, b?: FilterDTO): boolean {
  // do deep compare without JSON.stringify to avoid issues with different order of keys
  return JSON.stringify(a) === JSON.stringify(b);
}

export interface ApiCallState<T> {
  error?: string;
  result?: T;
}

export interface PaginatedResult<T> {
  data: T[];
  total: number;
}

export interface PaginationDTO {
  offset: number;
  limit: number;
}

export interface SortDTO<T> {
  sortField: keyof T;
  sortDirection: sortDirection;
}

export type FilterDTO = DateFilterDTO | FloatFilterDTO | StringFilterDTO | BooleanFilterDTO;

export interface ICapturerEvents extends Record<string, any> {
  capture_start_at: string;
  type: EventTypes;
}

export type EventTypes =
  | 'activity_started'
  | 'activity_ended'
  | 'datapoint_uploaded'
  | 'stitching_finished'
  | 'license_plate_read';
